var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-button',{staticClass:"mr-2",attrs:{"to":"/security/reports","variant":"primary"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"arrow-left"}}),_vm._v("Reports")],1),_c('b-button',{staticClass:"mr-2",attrs:{"href":_vm.reportCsvUrl,"variant":"secondary"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"box-arrow-up-right"}}),_vm._v("Export (CSV)")],1)],1),_c('b-col',{staticClass:"d-flex flex-row",attrs:{"cols":"12","md":"8"}},[_c('b-input-group',[_c('b-form-datepicker',{staticClass:"rounded-left",model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}}),_c('b-input-group-text',{staticClass:"rounded-0 border-left-0 border-right-0"},[_vm._v(" ‐ ")]),_c('b-form-datepicker',{staticClass:"rounded-right",model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[(_vm.loading)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"body-class":"py-5 text-center"}},[_c('b-card-text',[_c('b-spinner',{attrs:{"variant":"secondary"}}),_c('p',{staticClass:"font-weight-normal mb-0 mt-2 text-muted"},[_vm._v("Loading Report...")])],1)],1)],1):_vm._e()],1),_c('b-row',{staticClass:"mt-3"},[(!_vm.loading)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"responsive":"","striped":"","head-variant":"dark","fields":_vm.fields,"items":_vm.values},scopedSlots:_vm._u([{key:"cell(customer_name)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'font-weight-bold': item.ind > 1}},[_vm._v(_vm._s(item.customer_name))])]}},{key:"cell(quantity)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'font-weight-bold': item.ind > 1}},[_vm._v(_vm._s(item.quantity))])]}},{key:"cell(cost)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'font-weight-bold': item.ind > 1}},[_vm._v(_vm._s(item.cost !== "-" ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.cost) : '-'))])]}},{key:"cell(profit)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'font-weight-bold': item.ind > 1}},[_vm._v(_vm._s(item.profit ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.profit) : '£0.00'))])]}}],null,false,2681647208)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }