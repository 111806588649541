<template>
  <b-container>
    <b-row>
      <b-col cols="12" md="4">
        <b-button class="mr-2" to="/security/reports" variant="primary"><b-icon class="mr-2" icon="arrow-left" />Reports</b-button>
        <b-button class="mr-2" :href="reportCsvUrl" variant="secondary"><b-icon class="mr-2" icon="box-arrow-up-right" />Export (CSV)</b-button>
      </b-col>
      <b-col class="d-flex flex-row" cols="12" md="8">
        <b-input-group>
          <b-form-datepicker class="rounded-left" v-model="start"></b-form-datepicker>
          <b-input-group-text class="rounded-0 border-left-0 border-right-0"> &dash; </b-input-group-text>
          <b-form-datepicker class="rounded-right" v-model="end"></b-form-datepicker>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" v-if="loading">
        <b-card body-class="py-5 text-center">
          <b-card-text>
            <b-spinner variant="secondary"></b-spinner>
            <p class="font-weight-normal mb-0 mt-2 text-muted">Loading Report...</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" v-if="!loading">
        <b-table responsive striped head-variant="dark" :fields="fields" :items="values">
          <template #cell(customer_name)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.customer_name }}</span>
          </template>
          <template #cell(quantity)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.quantity }}</span>
          </template>
          <template #cell(cost)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.cost !== "-" ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.cost) : '-' }}</span>
          </template>
          <template #cell(profit)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.profit ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.profit) : '£0.00' }}</span>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loading: false,
      start: this.moment().startOf('month').toDate(),
      end: this.moment().endOf('month').toDate(),
      fields: [
        { key: 'customer_name', text: 'Details' },
        { key: 'quantity', text: 'Quantity' },
        { key: 'cost', text: 'Cost' },
        { key: 'profit', text: 'Profit' }
      ],
      values: []
    }
  },
  async mounted () {
    await this.load()
  },
  computed: {
    reportCsvUrl () {
      const params = new URLSearchParams([['start', this.moment(this.start).format('YYYY-MM-DD')], ['end', this.moment(this.end).format('YYYY-MM-DD')]])
      return `/api/reports/profit.csv?${params.toString()}`
    }
  },
  methods: {
    moment,
    async load () {
      this.loading = true
      const params = new URLSearchParams([['start', this.moment(this.start).format('YYYY-MM-DD')], ['end', this.moment(this.end).format('YYYY-MM-DD')]])
      const response = await window.axios.get('/reports/profit', { params })

      this.loading = false
      this.values = response.data
    }
  },
  watch: {
    async start () {
      await this.load()
    },
    async end () {
      await this.load()
    }
  }
}
</script>

<style>

</style>
